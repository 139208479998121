
<template>
    <div class="card card-body shadow-sm app-smcategory">
        <app-basic-table
            ref="basic_table"
            :table-name="$t('users.user list')"
            :filters.sync="filters"
            :setting-columns="columns"
            :endpoint-list="ENDPOINT.SMALL_CATEGORY_LIST"
            @resetAllSearch="onResetAllSearch"
            @searched="onSearch"
        >
            <template v-slot:filters>
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-md-6">
                        <div class="form-group">
                        <label>{{ filters.big_category_id.label }}</label>
                        <app-select
                            :name="filters.big_category_id.value"
                            input-style="normal"
                            v-model="filters.big_category_id.value"
                            :options-data="meta.big_category"
                        />
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6">
                        <div class="form-group">
                        <label>{{ filters.medium_category_id.label }}</label>
                        <app-select
                            :name="filters.medium_category_id.value"
                            input-style="normal"
                            v-model="filters.medium_category_id.value"
                            :options-data="meta.medium_category"
                        />
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:body-cell-big_category_id="props">
                <td class="app-align-middle">
                    <p :data-original-title="getBigCategoryName(props.row.big_category_id)" style="color: #007bff;" @click="handlerBigCategoryNameClick(props.row)"  class="qa_category_name app-table-p app-cell-tooltip mb-0">
                        {{getBigCategoryName(props.row.big_category_id)}}
                    </p>
                </td>
            </template>

            <template v-slot:body-cell-medium_category_id="props">
                <td class="app-align-middle">
                    <p :data-original-title="props.row.medium_category_name" style="color: #007bff;" @click="handlerMediumCategoryNameClick(props.row)"  class="qa_category_name app-table-p app-cell-tooltip mb-0">
                        {{props.row.medium_category_name}}
                    </p>
                </td>
            </template>

            <template v-slot:table-menu-right>
                <button @click="handleBtnCreateClick()" class="btn btn-warning">
                    {{ $t("common.create") }}
                </button>
            </template>
            <template v-slot:body-cell-edit="props">
                <td class="app-align-middle text-center app-p-none">
                <button
                    @click="handleBtnUpdateClick(props.row)"
                    class="btn btn-primary"
                >
                    {{ $t("common.edit") }}
                </button>
                </td>
            </template>
        </app-basic-table>
    </div>
</template>
<script>
import AppBasicTable from "@components/_common/list/AppBasicTable";
import {ROLES, BASIC_TABLE} from "@constants";
export default {
    name: "SmallCategoryList",

    components: {
        "app-basic-table": AppBasicTable
    },

    data() {
        return {
            idCache: null,
            paramGetList: {},
            filters: {
                big_category_id: {
                    name: "big_category_id",
                    condition: "equal",
                    label: this.$t("small-category.name_big"),
                },
                medium_category_id: {
                    name: "medium_category_id",
                    condition: "equal",
                    label: this.$t("small-category.name_medium"),
                },
            },
            columns: [
                {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
                {name: "name", label: this.$t("small-category.name"), sortable: true},
                {name: "note", label: this.$t("small-category.note")},
                {name: "big_category_id", label: this.$t("small-category.name_big"), sortable: true},
                {name: "medium_category_id", label: this.$t("small-category.name_medium"), sortable: true},
                {name: "edit", label: this.$t("common.edit")},
            ],
            data: {

            },
            meta : {
                big_category: [],
                medium_category: []
            }
        }
    },

    watch: {
        'filters.big_category_id.value':async function(value, oldValue) {
            await this.getMetaData({big_category_id: value, medium_category_id: this.filters.medium_category_id.value})
        },
    },

    methods: {

        getMetaData(params) {
            this.$request.get(this.ENDPOINT.UI_HELPER_CHAT_BOT_CATEGORY, params).then(res => {
                this.meta = {
                    big_category: res.data.big_category,
                    medium_category: res.data.medium_category
                }
                this.meta.big_category.unshift({
                    id: "all",
                    name: ""
                })
                this.meta.medium_category.unshift({
                    id: "all",
                    name: ""
                })
                let vm = this
                if (!this.meta.medium_category.find(({id}) => id === parseInt(vm.filters.medium_category_id.value))) {
                  this.filters.medium_category_id.value = 'all';
                }

            })
        },
        handleBtnCreateClick() {
            this.$router.push({name: this.ROUTES.ADMIN.SMALL_CATEGORY_CREATE})
        },

        handleBtnUpdateClick(entry) {
            this.$router.push({name: this.ROUTES.ADMIN.SMALL_CATEGORY_EDIT, params: {id: entry.id}})
        },

        handlerBigCategoryNameClick(entry) {
            let routeBigCategory = this.$router.resolve({name: this.ROUTES.ADMIN.BIG_CATEGORY_EDIT, params: {id: entry.big_category_id}});
            window.open(routeBigCategory.href, '_blank');
        },

        getBigCategoryName(big_category_id) {
            let big_category_name = this.meta.big_category.find(({id}) => id === big_category_id);
            if (big_category_name == undefined) {
                return '';
            }
            return big_category_name.name;
        },

        getBigEditUrl(big_category_id) {
            let props = this.$router.resolve({
                name: this.ROUTES.ADMIN.BIG_CATEGORY_EDIT,
                params: { id: big_category_id },
            });
            return props.href;
        },

        handlerMediumCategoryNameClick(entry) {
            let routeMediumCategory = this.$router.resolve({name: this.ROUTES.ADMIN.MEDIUM_CATEGORY_EDIT, params: {id: entry.medium_category_id}});
            window.open(routeMediumCategory.href, '_blank');
        },

        getMediumCategoryName(medium_category_id) {
            let medium_category_name = this.meta.medium_category.find(({id}) => id === medium_category_id);
            if (medium_category_name == undefined) {
                return '';
            }
            return medium_category_name.name;
        },

        getMediumEditUrl(medium_category_id) {
            let props = this.$router.resolve({
                name: this.ROUTES.ADMIN.MEDIUM_CATEGORY_EDIT,
                params: { id: medium_category_id },
            });
            return props.href;
        },

        onResetAllSearch() {
            this.$router.push({
                name: this.ROUTES.ADMIN.SMALL_CATEGORY_LIST, query: {
                    'filters.big_category_id.equal': 'all',
                    'filters.medium_category_id.equal' : 'all',
                }
            }).catch(e => {
                console.log(e)
            })
        },

        async onSearch() {
            // this.getTourSelectName();
        },
    },
}
</script>
<style>
    .app-smcategory .app-table-container .table th:nth-child(1) {
        width: 3.4%;
    }
    .app-smcategory .app-table-container .table th:nth-child(2),
    .app-smcategory .app-table-container .table th:nth-child(4),
    .app-smcategory .app-table-container .table th:nth-child(5) {
        width: 17%;
    }
    .app-smcategory .app-table-container .table th:nth-child(3) {
        width: 34%;
    }
    .app-smcategory .app-table-container .table th:nth-child(6) {
        width: 11.6%;
    }
    .qa_category_name {
        cursor: pointer;
    }
</style>
